import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../../Hooks/useBreakPoint';
import ReactDelighters from '../../Components/ReactDelighters';

function Landing(props) {
  const [user] = useOutlet('user');
  const showLoginModal = useOutletSetter('login-modal');
  const {passBreakpoint} = useBreakpoint(100);

  return (
    <ReactDelighters>
      <NavBar
        hasBorder={passBreakpoint}
        bgColor={passBreakpoint ? 'white' : 'transparent'}
        style={{height: 64}}>
        <img
          src="/images/yk-logo_vertical.png"
          alt="revtel"
          style={{width: 120, height: 40, opacity: 0.8, objectFit: 'contain'}}
        />
      </NavBar>

      <Wrapper>
        <HeroBannerSection style={{backgroundColor: '#ccc'}}>
          <img
            src="/images/yk-logo.png"
            alt="revtel"
            style={{width: 250, height: 250, objectFit: 'contain'}}
          />

          <h2>YK Order Management System</h2>
          <p style={{marginBottom: 20, color: 'white'}}>
            Most Advanced E-Commerce Order Management System
          </p>
          {user ? (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Button onClick={() => navigate('/admin')}>GO TO ADMIN</Button>
            </div>
          ) : (
            <Button onClick={() => showLoginModal(true)}>LOGIN</Button>
          )}
        </HeroBannerSection>
      </Wrapper>
    </ReactDelighters>
  );
}

function HeroBannerLogo(props) {
  return (
    <StaticImage
      src="../../images/react-icon.png"
      alt="Logo"
      placeholder="blurred"
      layout="fixed"
      width={256}
      height={256}
    />
  );
}

const Wrapper = styled.div`
  & > section {
    position: relative;
    overflow-x: hidden;
  }

  & .landing-slide-in-right {
    opacity: 0;
    transform: translateX(2000px);
    transition: 350ms;
  }

  & .landing-slide-in-right.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }

  & .landing-slide-in-left {
    opacity: 0;
    transform: translateX(-2000px);
    transition: 350ms;
  }

  & .landing-slide-in-left.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const NavBar = styled.div`
  position: fixed;
  background-color: ${(props) => props.bgColor};
  top: 0px;
  left: 0px;
  width: 100vw;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  box-shadow: ${(props) =>
    props.hasBorder
      ? '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
      : 'none'};
  transition: 200ms;
`;

const HeroBannerSection = styled.section`
  padding: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h2 {
    font-size: 32px;
    color: white;
  }
  & > p {
    font-size: 18px;
    color: #ccc;
  }
`;

const RowBannerSection = styled.section`
  padding: 40px 20px;

  & > .content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > .description {
      display: flex;
      flex-direction: column;
      & > h2 {
        font-size: 32px;
        color: #888;
      }
      & > p {
        font-size: 18px;
        color: #ccc;
      }
    }
  }
`;

const FlexItemSection = styled.section`
  padding: 40px;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
`;

const FlexItem = styled.div`
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .description {
    display: flex;
    flex-direction: column;
    & > h3 {
      font-size: 24px;
      color: #888;
    }
    & > p {
      font-size: 18px;
      color: #ccc;
    }
  }
`;

export default Landing;
